header .navbar{
    z-index: 10;
}
.navbar-collapse{
    position: fixed;
    background: $primary;
    width: 100vw;
    height: 100vh;
    top: -100vh;
    left: 0;
    transition: top .5s ease, opacity .5s ease;
    opacity: 50%;
   
    &.show{
        top:0;
        opacity: 100%;
        .full-page-menu{
            li.has-sub > ul::after{
                bottom: 0;
            }
        }
    }
}
.navbar-collapse.show + .navbar-toggler {
    position: fixed;
    z-index: 3;
}
.menu-brand{
    img{  
        transform: rotate(90deg) translate(50%, 150%);
        width: clamp(120px, 20vw, 400px);
    }
    @media screen and (max-width:576px) {
        img{
            transform: none;
        }
        position: absolute;
        width: clamp(150px, 25vw, 200px);
    }
    @media screen and (max-width:400px) {
        img{
            transform: none;
        }
        
    }
}
.full-page-menu{
    padding-top: 5rem;
    max-width: 60%;
    @media screen and (max-width:576px) {
        max-width: none;
    }
    .nav-link{
        font-size: 1.667rem;
    }
    li.has-sub{
        position: relative;
        display: flex;        
        
        & > ul{
            display: none;
            position: fixed;
            right: 0;
            width: max(200px, 30vw);
            background: $light-blue;
            &::after{
                content:"";
                position: fixed;
                top:0;
                bottom:100vh;
                right: 0;
                width: max(200px, 30vw);
                background: $light-blue;
                z-index: -1;
                transition: bottom .5s ease;
            }
            &::before{
                content: url("../assets/link-arrow.svg");
                filter: brightness(0)invert(1);
                color: white;
                left: 15px;
                top: 1rem;
                position: absolute;
            }
            .nav-item{
                &:hover{
                    background: $secondary;
                }
                
            }
        }
        &::after{
            content: "";
            left: 100%;
            display: block;
            background-color: transparent;
            height: 45px;
            width: 100%;
            position: absolute;
            z-index: -1;
        }
        &:hover{
            background: $light-blue;
            & > ul{
               display: block;
            }
            &::after{
                content: "";
                left: 100%;
                display: block;
                background-color:  $light-blue;
                height: 45px;
                width: 100%;
                position: absolute;
                z-index: -1;
            }
        }
       
    }
    // @media screen and (max-width:400px) {
    //     .depth-0 > .nav-link{
    //         padding-left:0!important;
    //         padding-right:0!important;
    //     }
    //     .depth-1{
    //         .nav-link{
    //             padding-left:1rem;
    //             padding-right:1rem;
    //         }
    //     }
    // }
}
.secondary-page-menu{
    hr{
        position: fixed;
        right: 0;
        border-color: #fff;
        width: 100vw;
        @media screen and (max-width:576px) {
            position: relative;
            width: 100%;
        }
    }
    // @media screen and (max-width:400px) {
    //     .depth-0 > .nav-link{
    //         padding-left:0!important;
    //         padding-right:0!important;
    //     }
    // }
}