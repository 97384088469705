

// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/




// Theme colors
$primary: #0352FA;
$secondary: #06BFF9;
$red: #FF0049;
$dark: #58595B;
$light: #F6F6F6;
$black: #000A1E;
$light-blue: #3D7AFE;
$lighter-blue: #9CBCFF;
// For other theme color
// refer to https://getbootstrap.com/docs/5.3/customize/sass/ for more details
// $success:       $green ;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red lt;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// Fonts
$primary-text-emphasis: #fff;
$body-emphasis-color: $light-blue;

$font-family-base: 'Roboto', Arial, Helvetica, sans-serif;
$font-size-base: 1rem;

// Links
$link-color: $secondary;
$link-decoration: none;

// Navbar
$nav-link-color: #fff;    
$nav-link-hover-color: $primary;

$navbar-light-hover-color: $primary;
$navbar-light-color: #fff;
$navbar-light-icon-color: #fff;

$font-size-root: 18px;

$navbar-toggler-focus-width: 0;
$navbar-toggler-font-size:18px;
$focus-ring-width: 1px;
$input-border-radius:  0px;
$input-bg: transparent;
$input-color: #fff;
$input-border-width: 0px;
$input-focus-bg: $light-blue;
// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

$h1-font-size: 4rem;     //72px
$h2-font-size: 3.333rem; //60px
$h3-font-size: 2.667rem; //48px
$h4-font-size: 2.222rem; //40px
$h5-font-size: 1.667rem; //30px
$h6-font-size: 1.333rem; //24px

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px
);

//Accordion
$accordion-button-active-bg:transparent;
$accordion-button-active-color:$primary;
$accordion-icon-color:$primary;
$accordion-icon-active-color:$primary;
$accordion-button-focus-border-color: transparent;
$accordion-button-focus-box-shadow: none;
$accordion-button-padding-x:0;
$accordion-border-width:0;

// Pagination 
$pagination-border-width: 0;
$pagination-border-radius: 0;
$pagination-disabled-color: #AAA;

$pagination-hover-color: #fff;
$pagination-hover-bg: $primary;
$pagination-focus-box-shadow: 0;
