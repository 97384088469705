
.blocks-content-with-bg > div > div{
    min-height: clamp(250px, 20vw, 500px);
}

.blocks-partners, .blocks-customers{
    img{
        filter: grayscale(1)opacity(50%);
        transition: filter 1s ease-in-out;
        &:hover{
            filter: grayscale(0)opacity(100%);
        }
    }
    
} 