//loading the editor instead of loading the boostrap since the editor contains the bootstrap library, and other style we specified for ckeditor.



@import "variables";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";


// Our own scss below

@import "nav";
@import "blocks";

// // TODO: Can we move this into the KMS?
// @import "~venobox/dist/venobox.css";
.cke_editable{
    background-color: #cfcfcf;
}
.vw-50{
    width: 50vw;
}
.text-light-blue{
    color:$lighter-blue;
}

.img-tint{
    background: #B1B1B1;
    img{
        mix-blend-mode: multiply;
    }
}
.slanting-lines{
    &::after{
        content:url('../assets/slanting_lines.svg.svg');
        position: absolute;
        z-index: 1;
        bottom:-7px;
        left:0;
        width:clamp(200px, 30vw, 1000px);
        display:block;
        opacity: .5;
    }
}
.horizontal-lines{
    position: relative;
    &::before, &::after{
        content:'';
        position: absolute;
        left:0;
        right:0;
        z-index: 0;
        border-bottom: 1px solid white;
        display:block;
    }
    &::after{
        top:50%;
    }
    &::before{
        top:90%;
    }
    & > *{
        position: relative;
        z-index: 1;
    }
}
.link-with-arrow{
    padding-right: 0;
    &::after{
        color:inherit;
        content:url("../assets/link-arrow.svg");
        display: inline-block;
        margin-left: 20px;
    }
    &.text-white{
        filter: brightness(0)invert(1);
    }
}


.container-400px{
   max-width: 400px; 
}

.carousel-indicators{
    bottom:90px;
    justify-content:flex-start;
}
.footer-brand{
    filter: brightness(0) saturate(100%) invert(19%) sepia(58%) saturate(6961%) hue-rotate(223deg) brightness(100%) contrast(98%)
}
.site-links > .site-link{
    width: auto;
    color: white;
}
.t-contact{
    main .site-links > .site-link{
        width: auto;
        color: $dark;
    }
    #map-click{
        min-height: 400px;
    }
}
.t-faq{
    .splash-content{
        display: none;
    }
}
blockquote{
    display: block;
    border-top:1px solid #9CBCFF;
    border-bottom:1px solid #9CBCFF;
    color: #9CBCFF;
    font-weight: 600;
    padding:1rem 2rem;
    text-align: center;
}
.arrow-prev > .pager-arrow-icon{
    content: url('../assets/link-arrow.svg');
    transform: rotate(180deg);
    margin-bottom: -3px;
    margin-right: 1rem;
}
.arrow-next > .pager-arrow-icon{
    content: url('../assets/link-arrow.svg');
    margin-bottom: -3px;
    margin-left: 1rem;
}

.share-bar{
    top:100px;
    background-color: $light-blue;

    svg{
        height: 36px;
        width: 36px;
        object-fit: contain;
        fill: #fff;
        transition: fill .3s ease;
        &:hover{
            fill: $primary;
        }
    }
}

.two-column{
    margin:0;
    padding:0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    grid-gap:2rem;
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
    }
    img{
        width:100%!important;
        height: auto!important;
    }
}
.scale-me{
    //initial size, to be overwritten via js
    width: 200px;
}
.image-divider{
    min-height: 400px;
    max-height: 750px;
}
hr{
    border-color: $primary;
    opacity:1;
}
.floating-spire-block{
    transform: translateY(-50%);
    z-index: 1;

    ul.two-column > li{
        border-right: 1px solid white;
        &:last-child{
            border-right: none;
        }
    }
    
}
.file-browse{
    text-decoration: underline;
}

.recaptcha-notice{
    color:$white;
    margin-top: 1rem;
    text-align: right;
}
.icon-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding:0;
    margin:0;
    width: 100%;
    list-style:none;
    & > *{
        &::after{
            content: url('../assets/link-arrow.svg');
        }
        &:last-child::after{
            content:'';
        }
        img{
            max-width: 150px;
            margin: 0 min(2vw, 2rem);
            width: 20vw!important;
            height: auto!important;
        }
    }
}